<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Modifier une Carte
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <form @submit.prevent="send" enctype="multipart/form-data">
            <v-text-field
              label="Désignation *"
              @input="
                formErrors && formErrors.name ? (formErrors.name = '') : false
              "
              :error-messages="
                formErrors && formErrors.name ? formErrors.name[0] : ''
              "
              v-model="form.name"
            />

            <v-col cols="12">
              <v-select
                prepend-icon="mdi-filter-outline"
                chips
                deletable-chips
                small-chips
                dense
                clearable
                :items="services"
                v-model="form.service_id"
                :item-text="'name'"
                :item-value="'id'"
                label="Service..."
                hide-details
                @change="serviceChanged($event)"
                @input="
                formErrors && formErrors.service_id
                  ? (formErrors.service_id = '')
                  : false
              "
              :error-messages="
                formErrors && formErrors.service_id ? formErrors.service_id[0] : ''
              "
              >
                <template v-slot:item="slotProps">
                  <div style="width: 50px">
                    <img
                      :src="getImageLink(slotProps.item.image)"
                      max-height="25"
                      max-width="25"
                      width="25"
                      height="25"
                      class="p-2"
                    />
                  </div>

                  {{ slotProps.item.name }}
                </template>
              </v-select>
            </v-col>

            <v-select
              disabled
              filled
              v-model="form.type"
              dense
              :items="cardTypes"
              label="Type du Carte "
              item-value="id"
              item-text="name"
              @input="
                formErrors && formErrors.type ? (formErrors.type = '') : false
              "
              :error-messages="formErrors.type"
            >
            </v-select>

            <v-text-field
              label="Prix (DZD)*"
              type="number"
              @input="
                formErrors && formErrors.amount
                  ? (formErrors.amount = '')
                  : false
              "
              :error-messages="
                formErrors && formErrors.amount ? formErrors.amount[0] : ''
              "
              v-model="form.amount"
            />
            <!-- <v-textarea name="input-7-1" label="Description" v-model="form.message"
                hint="Description du service"></v-textarea> -->

            <v-file-input
              label="Image *"
              accept="image/png, image/jpeg, image/jpg, .svg, .gif"
              show-size
              suffix="Image"
              @change="uploadFile"
              @focus="
                formErrors && formErrors.image ? (formErrors.image = '') : false
              "
              ref="file"
              chips
              prepend-icon="mdi-camera"
              :error-messages="formErrors.image"
            />

            <v-row>
              <v-col cols="6">
                Background color
                <v-text-field
                  v-model="form.background_color"
                  v-mask="mask"
                  hide-details
                  class="ma-0 pa-0"
                  solo
                >
                  <template v-slot:append>
                    <v-menu
                      v-model="menu"
                      top
                      nudge-bottom="105"
                      nudge-left="16"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                            v-model="form.background_color"
                            flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="form.active" label="Activée ?"/>
              </v-col>
            </v-row>

            <v-btn
              color="primary"
              class="rounded-lg text-none mt-3"
              :loading="btnLoading"
              large
              depressed
              @click="send"
            >
              <v-icon left>mdi-send-outline</v-icon>
              Envoyer
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,
      formErrors: [],
      services: [],

      errors: null,

      form: {
        image: "",
        name: "",
        amount: 0,
        type: null,
        background_color: "#000000",
        service_id: null,
        active: true
      },
      language_tab: null,

      card_id: null,

      // color picker
      mask: "!#XXXXXXXX",
      menu: false,

      cardTypes: [
        {
          id: "Idoom",
          name: "Idoom",
        },
        {
          id: "4G",
          name: "4G",
        },
      ],
    };
  },

  computed: {
    swatchStyle() {
      var color = this.form.background_color;
      var menu = this.menu;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  methods: {
    open(item) {
      if(this.$refs.file){
        this.$refs.file.reset();
      }
      this.form.amount = item.amount;
      this.form.name = item.name;
      this.form.type = item.type;
      this.form.active = item.active;
      this.form.service_id = item.service_id;
      if (item.background_color != null) {
        this.form.background_color = item.background_color;
      }
      this.card_id = item.id;
      this.dialog = true;
    },
    serviceChanged() {
      var type = this.services.find(
        (x) => x.id === this.form.service_id
      ).card_type;
      this.form.type = type;
    },
    close() {
      this.dialog = false;
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
    send() {
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      HTTP.post("/cards/" + this.card_id, formData)
        .then(() => {
          this.$successMessage = "Cette carte a été modiée avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};
          this.$refs.file.reset();
          this.$emit("refresh");
          this.dialog = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          if(err.response && err.response.data && err.response.data.errors){
            this.formErrors = err.response.data.errors;
          }
        });
    },

    uploadFile(file) {
      this.form.image = file;
    },
    getServices() {
      HTTP.get("/service/card", { params: {} })
        .then((res) => {
          this.services = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getServices();
  },
};
</script>
  
<style scoped>
</style>
  
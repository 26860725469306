<template>
    <v-container fluid>
        <div class="main">
            <v-row v-if="isLoading">
                <v-col cols="3" v-for="(el, i) in 6" :key="i">
                    <v-card class="rounded-lg" flat>
                        <v-skeleton-loader type="article,actions"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else>

                <v-col sm="12" lg="3" md="4" class="d-flex">
                    <v-card class="rounded-lg flex flex-column" flat :height="cards.length ? '' : '250'">
                        <v-card-title>
                            <v-btn icon class="btn--action ml-2" @click="resetFilters">
                                <v-icon size="18">mdi-refresh</v-icon>
                            </v-btn>
                            <v-menu v-model="filterMenu" :close-on-content-click="false" :close-on-click="false"
                                offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" class="btn--action ml-2">
                                        <v-icon size="18">mdi-filter-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Filtrages
                                        <v-spacer />
                                        <v-btn icon color="primary" @click="filterMenu = false" class="v-btn--active">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-divider />
                                    <v-card-text>
                                        <form @submit.prevent="filters">

                                            <v-select v-model="filter.selectedTypes" prepend-icon="mdi-filter-outline"
                                                multiple chips deletable-chips small-chips dense clearable
                                                :items="$store.state.service_types" :item-text="'name'"
                                                :item-value="'id'" label="Types..." hide-details></v-select>

                                            <v-select class="mt-3" v-model="filter.selectedStates"
                                                prepend-icon="mdi-filter-outline" multiple chips deletable-chips
                                                small-chips dense clearable :items="$store.state.service_states"
                                                :item-text="'name'" :item-value="'id'" label="Status..."
                                                hide-details></v-select>


                                            <v-btn type="submit" color="secondary lighten-1 mt-4" large depressed
                                                @click="handleFilter" class="rounded-lg text-none">
                                                <v-icon left>mdi-filter-outline</v-icon>
                                                Filtrer
                                            </v-btn>
                                        </form>
                                    </v-card-text>
                                </v-card>
                            </v-menu>

                        </v-card-title>
                        <div class="text-center">
                            <v-icon color="primary" @click="addCard" size="50">mdi-plus-circle
                            </v-icon>
                            <p class="mt-2 f-15">Creer une nouvelle carte</p>

                            <p class="px-2">
                                <v-btn color="primary" text class="mt-4 v-btn--active" to="/services/types" block large
                                    depressed>
                                    <v-icon left>mdi-cog</v-icon>
                                    Parametres
                                </v-btn>
                            </p>


                        </div>
                    </v-card>
                </v-col>

                <v-col sm="12" lg="3" md="4" v-for="el in cards" :key="el.id">
                    <v-card class="rounded-lg flex flex-column pt-0 card_box" flat>
                        <span class="price primary">{{ CurrencyFormatting(el.amount) }} DA</span>
                        <div class="text-center">

                            <v-img :src="getImageLink(el.image)" max-height="150" contain class="mt-0 img-card"></v-img>

                        </div>
                        <v-card-title class="f-15 py-3">{{ el.name }}</v-card-title>
                        
                        <v-card-actions>
                            <small class="d-block mt-2">
                                <v-icon small class="mt--5">mdi-clock-outline</v-icon>
                                {{ el.created_at }}
                            </small>
                            <v-spacer />
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>

                                    <v-list-item @click="updateCard(el)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Modifier</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />

                                    <v-list-item @click="deleteCard(el.id)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Supprimer</v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </v-menu>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <AddCardDialog ref="AddCardDialog" @refresh="fetchData" />
        <UpdateCardDialog ref="UpdateCardDialog" @refresh="fetchData" />
    </v-container>
</template>
<script>
import { HTTP } from "@/http-common";

import AddCardDialog from "./dialogs/AddCardDialog.vue";
import UpdateCardDialog from "./dialogs/UpdateCardDialog.vue";

export default {
    components: { AddCardDialog, UpdateCardDialog },
    data() {

        return {
            filterMenu: false,

            filter: {
            },
            pagination: {
                current: 1,
                total: 0,
            },
            delete_id: null,
            selectedCards: [],
            action: null

        }
    },

    methods: {
        fetchData() {
            this.$store.dispatch("fetchCards", {
                filter: this.filter,
            });
        },
        onPageChange() {
            this.fetchData();
        },

        deleteCard(id) {
            this.action = "removeOne";
            this.delete_id = id;
            this.$confirm_dialog = true;
        },
        removeCard() {

            HTTP.delete('/cards/' + this.delete_id).then(() => {
                this.delete_id = null;
                this.fetchData()
            }).catch(err => {
                console.log(err)
            })
        },
        
        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },

        addCard(){
            this.$refs.AddCardDialog.open()
        },
        updateCard(item) {
            this.$refs.UpdateCardDialog.open(item);
        },
        resetFilters(){
            this.filter= {}
        },
        handleFilter(){
            
        }


    },
    computed: {
        isLoading() {
            return this.$store.getters.getLoading;
        },
        cards() {
            return this.$store.getters.getCards;
        }

    },
    mounted() {
        this.fetchData();
    },
    watch: {
        $is_confirm: function (el) {
            console.log(el)
            if (el) {

                if (this.action == "removeOne") {
                    this.removeCard();
                }

                if (this.action == "removeMany") {
                    this.removeAll();
                }
                this.action = null;
                this.$is_confirm = false;
            }
        },
    },

}
</script>

<style scoped>
.expired {
    background-color: rgb(255 191 191);
}

.expired td {
    color: "white"
}

.card_box{
    position: relative;
}

.card_box .price{
    position: absolute;
    top: 0px;
    display: inline-block;
    /* background-color: darkgoldenrod; */
    color: #fff;
    padding: 4px 15px;
    z-index: 2;
    border-radius: 15px;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
    font-size: 16px;
}
.img-card{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
</style>